import React, { useState, useCallback } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Divider, Button, Paper } from '@mui/material';
import { isMobile } from 'react-device-detect';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Box, Pagination, Stack } from '@mui/material';
import { PalletProvider, usePallet } from '../../../../contexts/PalletContext';
// import ButtonIconLeft from '../../../components/ButtonIconLeft';
import useStyles from '../../../../components/layout/Styles';
// import PlaceIcon from '@material-ui/icons/Place';
import ProposicaoAcontecimentosService from '../../../../services/ProposicaoAcontecimentosService';
import { formatDate } from '../../../../utils/dateUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputDatePicker from '../../../../components/InputDatePicker';
import InputMultilineTextField from '../../../../components/InputMultilineTextField'
// import ButtonIconSalvar from '../../../components/ButtonIconSalvar';
import SolidButton from '../../../../components/SolidButton';
import CloseIcon from '@mui/icons-material/Close';
import AnexosComponent, { EAnexoModulos } from '../../../../components/Anexos';
// import AnexosItemComponent from '../../../components/Anexos';
import { useAlert } from '../../../../contexts/AlertContext';
import UploadFilesService from '../../../../services/UploadFiles.service';
import MenuButtonIconNoText from "../../../../components/MenuButtonIconNoText";
import { MoreHoriz } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import CustomizedDialogs from './DialogEdit';
import MenuItem from '@mui/material/MenuItem';
import Menu, { MenuProps } from '@mui/material/Menu';
import EmptyErrorDoc from '../../../../components/EmptyErrorDoc';
import ButtonDialogConfirmation from '../../../../components/ButtonDialogConfirmation';
import CircularLoading from '../../../../components/CircularLoading';
import FilterQuantity from '../../../../components/filter/FilterQuantity';
import AcontecimentosProposicaoEdicao from './TramitacaoProposicaoEdicao';
import ProposicaoAcontecimentosCreateSchema from '../../models/ProposicaoAcontecimentosCreateSchema'
import ProposicaoService from '../../../../services/ProposicaoService';
import ProposicaoTramitacaoCreateSchema from '../../models/ProposicaoTramitacaoCreateSchema';
import SelectTextFieldA from '../../../../components/SelectTextFieldA';
import EmptyErrorDocCarousel from '../../../../components/EmptyErrorDocCarousel';
import TramitacaoProposicaoEdicao from './TramitacaoProposicaoEdicao';
import PaginationSaveState from '../../../../components/PaginationSaveState';
import TabelasService from '../../../../services/TabelasService';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import * as helpers from '../../../../helpers/helpers';
import moment from 'moment';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '0px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


export interface IFileInfo {
    name: string,
    filename: string,
    size: number,
    new: boolean,
    file: any
}

export default function ProposicaoTramitacoes(props: { id_proposicao: number, index: number, activeTab: number, proposicao_integrada?: boolean, onChange?: any,propCasa?:number }) {

    const { NewAlert } = useAlert();
    const classes = useStyles();
    const { pallet } = usePallet();
    const [loading, setLoading] = useState(false)
    const [tramitacoes, setTramitacoes] = useState<Array<any>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [infoList, setInfoList] = useState<any>();
    const [dataTramitacao, setDataTramitacao] = useState();
    const [situacaoTramitacao, setSituacaoTramitacao] = useState<any>(null);
    const [tramitacoesSituacoes, setTramitacoesSituacoes] = useState([{ key: 0, label: '', value: 0 }]);
    const [descricao, setDescricao] = useState("");
    const [idReferencia, setIdReferencia] = React.useState<any>();
    const [camposPreenchidos, setCamposPreenchidos] = useState(false);
    // const [limparAnexosSalvos, setLimparAnexosSalvos] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState();
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
        setCurrentPage(1)
    };
    const [error, setError] = useState<Array<{ name: string, err: string }>>([]);
    const [data, setData] = useState({});
    const [loadingForm, setLoadingForm] = useState(false)
    const [totalLista, setTotalLista] = useState<number>()
    const [loadingAfterSalvar, setLoadingAfterSalvar] = useState(false);

    const [expandedAcordion, setExpandedAcordion] = useState(false);
    const { user } = useAuthorization();

    const PaperContent = styled(Paper)`
	color: ${pallet.textColorQuaternary};
	font-size: ${pallet.fontSize.titleWidgets};
	font-family: ${pallet.fontFamily.general};
	font-weight: 600;
	padding-top: 5%;
	padding-bottom: 10%;
	width: 100%;
	text-align: center;
	background-color: #ffff;    
`;

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }


    React.useEffect(() => {
        if (props.activeTab === props.index)
            loadTramitacoes(props.id_proposicao)
    }, [props.activeTab, currentPage, currentPageSize]);

    // React.useEffect(() => {
    //     if (currentPage > 0) 
    //     loadAcontecimentos(props.id_proposicao)

    // }, [currentPage, currentPageSize]);

    // React.useEffect(() => {
    //     if (dataAcontecimentos && descricao !== "") {
    //         setCamposPreenchidos(true)
    //     }
    // }, [descricao, dataAcontecimentos]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpen(id)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(undefined)
    };


    const handleDataTramitacaoChange = (dateAc) => {
        setDataTramitacao(dateAc)
    };

    const handleDescricaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescricao(event.target.value)
    };

    const handleSituacaoTramitacaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSituacaoTramitacao(event.target.value);
    };

    const loadingListAfterSalvar = () => {
        setLoadingAfterSalvar(true)

        
        setTimeout(() => {
            loadTramitacoes(props.id_proposicao);
            setLoadingAfterSalvar(false)
        }, 2000);
        props.onChange()
    }

    const handleChangeAcordion =
        (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedAcordion(!ativo);
        };

    async function loadTramitacoes(id_proposicao: number) {
        setLoading(true)
        await ProposicaoService.GetTramitacao(id_proposicao, currentPage, currentPageSize)
            .then(tramitacoes => {

                setTotalLista(tramitacoes?.listInfo?.totalItens)

                if (tramitacoes && tramitacoes.listInfo) {
                    setInfoList(() => { return { ...tramitacoes.listInfo } })
                    setLoading(false)
                };
                if (tramitacoes && tramitacoes.lista) {
                    setTramitacoes(() => { return [...tramitacoes.lista] })
                    setLoading(false)
                };
    
                loadTramitacoesSituacoes();
                props.onChange();

            })
    }

    async function loadTramitacoesSituacoes() {

        TabelasService.getTabelaTramitacaoSituacoes()
            .then(res => {
                //console.log(" Resultado lista situações :",res);
                const dataresp = res?.map(item => {
                    return {
                        key: item.codsituacao,
                        label: item.descsituacao,
                        value: item.codsituacao
                    }
                });
                dataresp && setTramitacoesSituacoes(dataresp);
            })

    }

    React.useEffect(() => {
        if (!idReferencia && props.id_proposicao) {
            setDataTramitacao(undefined)
            setSituacaoTramitacao(undefined);
            setDescricao("")

            loadTramitacoes(props.id_proposicao);
        }
    }, [idReferencia])

    async function handleOnAfterUpload() {
        if (idReferencia) {
            setIdReferencia(undefined);
        }
        loadingListAfterSalvar()
    }

    const setPage = (event: object, page: number) => setCurrentPage(page);

    const postTramitacao = async (e) => {
        setLoading(true)

        e.preventDefault();
        const data = {
            date: dataTramitacao ? dataTramitacao : new Date(),
            situacao: situacaoTramitacao,
            descricao: descricao,
        }

        setError(() => [])
        data && ProposicaoTramitacaoCreateSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoTramitacaoCreateSchema.validate(data).catch(function (err) {
        });

        if (ProposicaoTramitacaoCreateSchema.isValidSync(data)) {
            await ProposicaoService.CreateTramitacao(data, props?.id_proposicao)
                .then(res => {
                    let idTramitacao = res
                    if (res && res > 0) {
                        setIdReferencia(idTramitacao)
                        NewAlert('success', 'Tramitação cadastrada com sucesso')
                        setLoadingForm(true)
                        setTimeout(() => {
                            setIdReferencia(undefined);
                            setLoadingForm(false)
                            setLoading(false)
                        }, 100);
                        loadingListAfterSalvar();
                    } else {
                        setLoadingForm(false)
                        setLoading(false)
                        NewAlert('error', 'Erro ao cadastrar, verifique os campos e salve novamente');
                    }
                })//.then(x => loadAcontecimentos(props.id_proposicao))
        } else {
            NewAlert('error', 'Algum campo está incorreto ou vazio, verifique!');
            setTimeout(() => {
                setLoadingForm(false)
                setLoading(false)
            }, 100);
        }
    }

    const deletarEdicaoTramitacoes = (id) => {
        ProposicaoService.DeleteTramitacao(id).then(res => {
            if (res) {
                handleClose()
                NewAlert('success', 'Tramitação apagada com sucesso')
                setExpandedAcordion(false)
                loadTramitacoes(props?.id_proposicao)
                props.onChange()
            } else {
                NewAlert('error', 'Erro ao apagar, verifique os campos e apague novamente');
            }
        })

    }


    const erroAlteracaoPerfil = () => {
        NewAlert(
            "error",
            "Não é possível realizar está ação com este perfil."
        );
    }
    
    return (
        <div
        // style={{ minHeight: "800px" }}
        >
            {/* ---------------------------- CONTEÚDO DE TRAMITAÇÕES ------------------------------ */}
            {/* <CustomizedDialogs /> */}
            <div style={{ fontFamily: `${pallet.fontFamily.general}` }}>

                {
                    loadingForm ?

                        <CircularLoading
                            size={80}
                            top={"90%"}
                            left={"50%"}
                            zIndex={2}
                        />

                        :

                        !props.proposicao_integrada ?
                            <Grid>

                                < Grid >
                                    <Grid style={{ paddingLeft: `${isMobile ? "0px" : "30px"}`, paddingRight: `${isMobile ? "0px" : "30px"}`, paddingBottom: `${isMobile ? "20px" : "45px"}` }}>
                                        <Accordion elevation={0} style={{ border: "1px solid #EAEAEA" }} onChange={handleChangeAcordion(expandedAcordion)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: `${pallet.color.primary.font}` }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    backgroundColor: `${pallet.color.primary.background}`,
                                                    borderRadius: "5px",
                                                    paddingLeft: "20px",
                                                    paddingRight: "20px",
                                                    flexFlow: "row-reverse",
                                                    height: "64px"
                                                }}
                                            >
                                                <Typography className={classes.heading} style={{
                                                    color: `${pallet.color.primary.font}`,
                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                                    fontWeight: pallet.fontWeight.subtitle,
                                                    marginLeft: "10px"
                                                }}>
                                                    NOVO REGISTRO
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{
                                                fontFamily: `${pallet.fontFamily.general}`
                                            }}>
                                                <Grid container spacing={2} style={{ padding: "25px 20px 20px 20px" }}>
                                                    <Grid item lg={6} xs={12}>
                                                        <InputDatePicker
                                                            onChange={handleDataTramitacaoChange}
                                                            label={"DATA DE REGISTRO"}
                                                            inputFormat={"dd/MM/yyyy"}
                                                            value={dataTramitacao}
                                                            defaultValue={dataTramitacao}
                                                            id={"dataRegistro-tramitacao"}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <SelectTextFieldA
                                                            value={situacaoTramitacao}
                                                            //onChange={handleSituacaoTramitacaoChange}
                                                            onChange={(e) => handleSituacaoTramitacaoChange(e)}
                                                            options={tramitacoesSituacoes}
                                                            label={"SITUAÇÃO"}
                                                            id={"situacao-tramitacao"}
                                                        />
                                                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                            <ErrorMessage name='situacao' errors={error} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{ marginTop: "5px" }} lg={12} xs={12}>
                                                        <InputMultilineTextField
                                                            type={"text"}
                                                            maxRows={4}
                                                            value={descricao}
                                                            defaultValue={descricao}
                                                            onChange={handleDescricaoChange}
                                                            id={"descricao-tramitacao"}
                                                            label="DESCRIÇÃO"
                                                        />
                                                        <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                            <ErrorMessage name='descricao' errors={error} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>

                                            <Grid style={{ paddingBottom: "10px" }}>
                                                <Divider />
                                            </Grid>

                                            <Grid style={{ padding: "20px 18px 20px 18px" }}>
                                                <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                    <Grid style={{
                                                        fontWeight: pallet.fontWeight.subtitle,
                                                        fontSize: `${pallet.general.size12}`,
                                                        color: `${pallet.color.tertiary.font}`,
                                                        marginLeft: "9px"
                                                    }}>
                                                        ANEXOS
                                                    </Grid>

                                                    <Grid className="container">
                                                        <AnexosComponent
                                                            label=''
                                                            modulo={EAnexoModulos.Tramitacao}
                                                            id_referencia={idReferencia}
                                                            canEdit={true}
                                                            autosave={true}
                                                            onAfterUpload={handleOnAfterUpload}
                                                        // limparAnexo={limparAnexosSalvos}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Divider />
                                            </Grid>
                                            <Grid container justifyContent="flex-end"
                                                style={{
                                                    backgroundColor: `${pallet.backgroundColorTertiary}`,
                                                    padding: "35px 30px 35px 30px"
                                                }}>

                                                <Grid>
                                                    <SolidButton
                                                        onClick={user.id_permissao !== 3 ? postTramitacao : erroAlteracaoPerfil}
                                                        // disabled={lockSaveButton}
                                                        color={`${pallet.textColorPrimary}`}
                                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                                        fontSize={`${pallet.general.size14}`}
                                                        title={"SALVAR"}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                                {
                                    //infoList?.totalItens === undefined || (infoList?.totalItens <= 0 && !infoList?.totalNoFilter) &&
                                    tramitacoes?.length ? (
                                        < Divider />

                                    ) : !expandedAcordion && (
                                        <Grid
                                            style={{
                                                color: `${pallet.textColorQuaternary}`,
                                                fontSize: `${pallet.fontSize.titleWidgets}`,
                                                fontFamily: `${pallet.fontFamily.general}`,
                                                fontWeight: 600,
                                                textAlign: "center",
                                                paddingBottom: "50px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <EmptyErrorDoc title="Comece criando uma nova Tramitação"
                                                description="Você ainda não criou nenhuma Tramitação para esta proposição."
                                                subdescription="" />
                                        </Grid>

                                    )}
                            </Grid>
                            :

                            //infoList?.totalItens === undefined || (infoList?.totalItens <= 0 && !infoList?.totalNoFilter) &&
                            !tramitacoes?.length &&
                            <Grid>
                                <PaperContent>
                                    <EmptyErrorDocCarousel title="Esta proposição não possui Tramitações" description="" />
                                </PaperContent>
                            </Grid>
                }
                <Grid style={{ padding: `${isMobile ? "5px 5px 0px 15px" : "0px 35px 20px 40px"}` }}>
                    {
                        totalLista <= 4 ?
                            null
                            :
                            <Grid style={{ marginTop: "20px", marginBottom: "20px" }}>
                                <FilterQuantity
                                    list={infoList}
                                    currentPageSize={currentPageSize}
                                    onChange={handleOnChangeItensPerPageSize}
                                />
                            </Grid>
                    }
                    {
                        loading ?
                            <CircularLoading
                                size={80}
                                top={"50%"}
                                left={"50%"}
                                zIndex={2}
                            />
                            :
                            loadingAfterSalvar === true ?
                                <CircularLoading
                                    size={80}
                                    top={"50%"}
                                    left={"50%"}
                                    zIndex={2}
                                />
                                :
                                <Box style={{ marginTop: "15px" }}>
                                    <Stepper orientation="vertical">
                                        {tramitacoes?.map((item, index) => (
                                            <Step expanded active={false}>
                                                <StepLabel
                                                    icon={
                                                        <Grid container >
                                                            <Grid
                                                                style={{
                                                                    marginTop: "-8px",
                                                                    marginLeft: "-7px",
                                                                    borderRadius: "50%",
                                                                    width: "42px",
                                                                    height: "42px",
                                                                    position: "relative",
                                                                    boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                    backgroundColor: (index === 0 && currentPage === 1) ? `${pallet.backgroundColorQuintenary}` : `${pallet.backgroundColorQuaternary}`
                                                                }}
                                                            >
                                                                <Grid style={{
                                                                    position: "absolute",
                                                                    borderRadius: "50%",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    width: "15px",
                                                                    height: "15px",
                                                                    margin: "-7.5px 0px 0px -7.5px",
                                                                    zIndex: 1,
                                                                    backgroundColor: "white",
                                                                    border: (index === 0 && currentPage === 1) ? `3px solid ${pallet.backgroundColorPrimary}` : `3px solid ${pallet.general.color4}`
                                                                }} >
                                                                    {(index === 0 && currentPage === 1) ?
                                                                        <Grid style={{
                                                                            position: "absolute",
                                                                            top: "50%",
                                                                            left: "50%",
                                                                            borderRadius: "50%",
                                                                            width: "7px",
                                                                            height: "7px",
                                                                            margin: "-3.5px 0px 0px -3.5px",
                                                                            zIndex: 1,
                                                                            backgroundColor: `${pallet.backgroundColorPrimary}`
                                                                        }}>

                                                                        </Grid>
                                                                        : null}

                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    optional={
                                                        <Grid container justifyContent={"space-between"}>
                                                            <Grid>
                                                                <Grid container style={{ fontSize: "12px", fontFamily: `${pallet.general.fontFamily}` }}>
                                                                    <Grid item style={{ marginRight: "5px" }}>
                                                                        {moment(item.data_evento).format("DD/MM/YYYY")}
                                                                    </Grid>
                                                                    {/* <Grid item style={{ marginTop: "-12px", marginRight: "5px", fontSize: "25px" }}>
                                                            •
                                                        </Grid>
                                                        <Grid item style={{}}>
                                                            {formatDate(new Date(item.data_evento), "HH:mm")}
                                                        </Grid> */}
                                                                </Grid>
                                                            </Grid>

                                                            {/* Editar e apagar ...  */}
                                                            {!props.proposicao_integrada &&
                                                                <Grid style={{ marginTop: "-25px", paddingRight: "10px", marginBottom: "-30px" }}>
                                                                    <Button
                                                                        id={`button-${item.id}`}
                                                                        aria-controls={anchorEl ? `mmenu-${item.id}` : undefined}
                                                                        disableElevation
                                                                        variant="contained"
                                                                        size="large"
                                                                        style={{
                                                                            backgroundColor: `${pallet.general.color3}`,
                                                                            textTransform: 'capitalize',
                                                                            fontFamily: `${pallet.fontFamily.general}`,
                                                                            fontWeight: pallet.fontWeight.button,
                                                                            fontSize: `${pallet.fontSize.smallButton}`,
                                                                            color: `${pallet.color.secundary.font}`,
                                                                            width: "25px",
                                                                            height: "40px"
                                                                        }}
                                                                        onClick={(e) => handleClick(e, item.id)}
                                                                        endIcon={<Grid style={{ fontSize: "30px", color: pallet.general.color4, marginTop: "9px", marginRight: "10px" }}>
                                                                            <MoreHoriz fontSize='inherit' />
                                                                        </Grid>
                                                                        }
                                                                    >
                                                                    </Button>
                                                                    <StyledMenu
                                                                        id={`mmenu-${item.id}`}
                                                                        MenuListProps={{
                                                                            'aria-labelledby': `button-${item.id}`,
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open === item.id}
                                                                        onClose={handleClose}
                                                                    >
                                                                        <MenuItem >
                                                                            <TramitacaoProposicaoEdicao
                                                                                idTramitacao={item.id}
                                                                                reloadList={() => loadTramitacoes(props?.id_proposicao)}
                                                                                closeMenu={() => handleClose()}
                                                                                onChange={() => props?.onChange()}
                                                                                idProposicao={props?.id_proposicao}
                                                                            />
                                                                        </MenuItem>
                                                                        <Divider style={{ marginTop: "0px", marginBottom: "0px" }} />
                                                                        <MenuItem
                                                                            style={{
                                                                                fontFamily: `${pallet.fontFamily.general}`,
                                                                                fontWeight: pallet.fontWeight.button,
                                                                                fontSize: `${pallet.general.size14}`,
                                                                                color: `${pallet.color.tertiary.font}`,
                                                                                borderRadius: "6px",
                                                                                padding: "6px",

                                                                            }}>
                                                                            <ButtonDialogConfirmation
                                                                                styles={{
                                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                                    fontWeight: pallet.fontWeight.button,
                                                                                    fontSize: `${pallet.general.size14}`,
                                                                                    color: `${pallet.color.tertiary.font}`,
                                                                                    borderRadius: "6px",
                                                                                    textTransform: "capitalize"
                                                                                }}
                                                                                title={"CONFIRMAÇÃO"}
                                                                                // lastPath={"/parlamentar"}                                                                    
                                                                                confirmationEvent={() => user.id_permissao !== 3 ? deletarEdicaoTramitacoes(item.id) :                                         NewAlert(
                                                                                    "error",
                                                                                    "Não é possível realizar está ação com este perfil."
                                                                                )}
                                                                                title_button={"Apagar"}
                                                                                confirmation_button={"Apagar"}
                                                                                content={"APAGAR REGISTRO?"}
                                                                                message={"Esta ação não poderá ser desfeita."}
                                                                            >
                                                                                <DeleteIcon style={{
                                                                                    color: `${pallet.color.tertiary.font}`,
                                                                                    marginRight: "16px",
                                                                                    fontFamily: `${pallet.fontFamily.general}`,
                                                                                }} />

                                                                            </ButtonDialogConfirmation>
                                                                        </MenuItem>
                                                                    </StyledMenu>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                >
                                                    {item.casa}
                                                </StepLabel>
                                                <StepContent>
                                                    <Grid style={{ padding: "15px", border: "1px solid #EAEAEA", borderRadius: "6px", backgroundColor: (index === 0 && currentPage === 1) ? `${pallet.backgroundColorQuintenary}` : null }}>
                                                        <Grid>
                                                            <Typography style={{
                                                                fontFamily: `${pallet.general.fontFamily}`,
                                                                fontSize: "13px",
                                                                fontWeight: pallet.fontWeight.subtitle
                                                            }}>
                                                                {item.desc_situacao}
                                                            </Typography>
                                                            {props.propCasa <= 3 ?
                                                            <Typography
                                                                style={{
                                                                    wordBreak: "break-word",
                                                                    padding: "10px",
                                                                    // boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                                    fontSize: "13px"
                                                                }}>
                                                                <b>{item.sigla}</b> - {item.sigla_descricao}
                                                            </Typography>
                                                            :
                                                            null
                                                            }
                                                            <Typography
                                                                style={{
                                                                    wordBreak: "break-word",
                                                                    padding: "10px",
                                                                    // boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                                    fontSize: "13px"
                                                                }}>
                                                                {item.descricao.slice(0,item.descricao.search('Próximos Passos:'))}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    wordBreak: "break-word",
                                                                    padding: "10px",
                                                                    // boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                                    fontSize: "13px"
                                                                }}>
                                                                {item.descricao.slice(item.descricao.search('Próximos Passos:'),item.descricao.length)}
                                                            </Typography>
                                                            {item.url ? 
                                                            <Typography
                                                                style={{
                                                                    wordBreak: "break-word",
                                                                    padding: "10px",
                                                                    // boxShadow: "1px 0.5px 4px 0 rgb(0 0 0 / 10%), 0 3px 10px 0 rgb(0 0 0 / 9%)",
                                                                    fontFamily: `${pallet.general.fontFamily}`,
                                                                    fontSize: "13px"
                                                                }}>
                                                                    {props.propCasa === 1 || props.propCasa === 3 ?

                                                                    <a href={helpers.extrairUrlCompleta(item.url.replace('http', 'https').replace('"}]', ''))} target='_blank'>{helpers.extrairUrlCompleta(item.url.replace('"}]', ''))}</a>
                                                                        :
                                                                        <a href={item.url} target='_blank'>{item.url}</a>
                                                                        

                                                                    
                                                                    }


                                                            </Typography>

                                                            : null}
                                                            
                                                        </Grid>
                                                        <Grid>
                                                            <Grid className="container">
                                                                <AnexosComponent
                                                                    label={''}
                                                                    modulo={EAnexoModulos.Tramitacao}
                                                                    id_referencia={item.id}
                                                                    canEdit={false}
                                                                    autosave={false}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                    }

                    {
                        //(infoList?.totalItens > 0 && !infoList?.totalNoFilter) &&
                        tramitacoes.length > 0 &&

                        <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "40px" : "0px"}` }}>

                            <Stack spacing={2}>
                                {/* <Pagination
                                    size={isMobile ? "small" : "large"}
                                    onChange={setPage}
                                    count={infoList?.totalPages}
                                    page={currentPage}
                                    shape="rounded"
                                /> */}
                                <PaginationSaveState
                                    //size={isMobile ? "small" : "large"}
                                    name={"ProposicaoTramitacaoListCurrentPage"}
                                    page={currentPage}
                                    pages={infoList?.totalPages}
                                    onChange={setCurrentPage}
                                //shape="rounded"
                                />
                            </Stack>

                        </Grid>
                    }

                </Grid>


            </div>
        </div>
    )
}
