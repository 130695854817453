import React, { useState } from 'react';
import { isMobile } from "react-device-detect";
import moment from "moment";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import LinkIcon from "@mui/icons-material/Link";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useStyles from "../../../../components/layout/Styles";
import { usePallet } from "../../../../contexts/PalletContext";
import { IProposicao } from "../../../../models/Proposicao.Model";
import { formatDate } from "../../../../utils/dateUtils";

import ProposicaoConsultaInformacoesModalEspecifica from "./ProposicaoConsultaInformacoesModalEspecifica";
import { useAuthorization } from "../../../../contexts/AuthorizationContext";

import ComJabuti from "../../../../img/michaelangelo_com_jabuti.png";
import SemJabuti from "../../../../img/michaelangelo_sem_jabuti.png";
import NdaJabuti from "../../../../img/michaelangelo_nda_jabuti.png";


export const ProposicaoConsultaInformacoes = (props: {
    proposicao: IProposicao;
    ia: any;
}) => {
    const { getConfig } = useAuthorization();

    const { pallet } = usePallet();
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);
    const maxCharacters = 400;

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const description = props.ia?.response.jabuti?.descricao;
    let limitedDescription 
    
    if(description){
        limitedDescription = description.length > maxCharacters && !expanded
        ? description.substring(0, maxCharacters)
        : description;
    };

    const Estados = (value: number) => {
        switch (value) {
            case 5:
                return 'Acre';
            case 6:
                return 'Alagoas';
            case 7:
                return 'Amapá';
            case 8:
                return 'Amazonas';
            case 9:
                return 'Bahia';
            case 10:
                return 'Ceará';
            case 11:
                return 'Distrito Federal';
            case 12:
                return 'Espírito Santo';
            case 13:
                return 'Goiás';
            case 14:
                return 'Maranhão';
            case 15:
                return 'Mato Grosso';
            case 16:
                return 'Mato Grosso do Sul';
            case 17:
                return 'Minas Gerais';
            case 18:
                return 'Pará';
            case 19:
                return 'Paraíba';
            case 20:
                return 'Paraná';
            case 21:
                return 'Pernambuco';
            case 22:
                return 'Piauí';
            case 23:
                return 'Rio de Janeiro';
            case 24:
                return 'Rio Grande do Norte';
            case 25:
                return 'Rio Grande do Sul';
            case 26:
                return 'Rondônia';
            case 27:
                return 'Roraima';
            case 28:
                return 'Santa Catarina';
            case 29:
                return 'São Paulo';
            case 30:
                return 'Sergipe';
            case 31:
                return 'Tocantins'
            default:
                return "";
        }
    };

    return (
        <Grid
            style={{
                paddingLeft: `${isMobile ? "10px" : "50px"}`,
                paddingRight: `${isMobile ? "10px" : "50px"}`,
                paddingTop: "10px",
                paddingBottom: "10px",
            }}
        >
            <Accordion
                defaultExpanded={true}
                elevation={0}
                style={{ border: "1px solid #EAEAEA" }}
            >
                <AccordionSummary
                    expandIcon={
                        <ExpandMoreIcon style={{ color: `${pallet.general.color1}` }} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                        height: "10px",
                        borderRadius: "5px",
                        paddingLeft: `${isMobile ? "5px" : "20px"}`,
                        paddingRight: `${isMobile ? "5px" : "20px"}`,
                        flexFlow: "row-reverse",
                    }}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: "100%" }}
                    >
                        <Grid item>
                            <Typography
                                className={classes.heading}
                                style={{
                                    color: `${pallet.textColorTertiary}`,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size14}`,
                                    fontWeight: pallet.general.weight_600,
                                    marginLeft: "10px",
                                }}
                            >
                                INFORMAÇÕES
                            </Typography>
                        </Grid>
                        {getConfig()?.habIa
                            ?
                            <>
                                {props.ia?.success && props.ia?.response.jabuti
                                    ?
                                    <Grid item>
                                        <Tooltip
                                            title={
                                                <div>
                                                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                        {props.ia?.response.jabuti.jabuti ? "JABUTI IDENTIFICADO !" : "JABUTI NÃO IDENTIFICADO."}
                                                    </Typography>
                                                    <Typography variant="body2" style={{ marginTop: '3px', fontSize: '0.8rem' }}>
                                                        {limitedDescription}
                                                        {description.length > maxCharacters && (
                                                            <span onClick={handleExpandClick} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                                                                {expanded ? (
                                                                    <ExpandLessIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                                                                ) : (
                                                                    <ExpandMoreIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                                                                )}
                                                            </span>
                                                        )}
                                                    </Typography>
                                                    <Grid 
                                                        style={{
                                                            fontSize: pallet.general.size14,
                                                            marginTop: "2px",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <Grid>
                                                            <LinkIcon/>
                                                        </Grid>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Grid >
                                                            {props.ia?.response.jabuti.id_proposicao_principal ? (
                                                                <a
                                                                    target="_blank"
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        fontWeight: pallet.general.weight_600,
                                                                    }}
                                                                    href={`/proposicao/${props.ia?.response.jabuti.id_proposicao_principal}`}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    PROPOSIÇÃO PRINCIPAL
                                                                </a>
                                                            ) : (
                                                                "PROPOSTA NÃO INFORMADA"
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Typography
                                                        variant="caption"
                                                        style={{
                                                            display: "block",
                                                            marginTop: "8px",
                                                            fontSize: '0.6rem'
                                                        }}
                                                    >
                                                        {props.ia?.response.jabuti.criado_em
                                                            ?
                                                            <>Data de processamento: {moment(props.ia?.response.jabuti.criado_em).format("DD/MM/yyyy HH:mm")}</>
                                                            :
                                                            <>Não processado.</>
                                                        }
                                                    </Typography>
                                                </div>
                                            }
                                            arrow
                                        >
                                            <img
                                                src={props.ia?.response.jabuti.jabuti ? ComJabuti : SemJabuti}
                                                alt="Icone"
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    marginLeft: "auto",
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    :
                                    null
                                }
                            </>
                            :
                            null
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        fontFamily: `${pallet.general.fontFamily}`,
                    }}
                >
                    <Grid container style={{ width: "100%" }}>
                        <Grid
                            item
                            lg={12}
                            xs={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size14,
                                marginTop: "10px",
                                marginLeft: "20px",
                            }}
                        >
                            DADOS LEGISLATIVOS
                        </Grid>

                        <Grid
                            item
                            lg={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size12,
                                marginLeft: "20px",
                                marginTop: "10px",
                            }}
                        >
                            EMENTA
                        </Grid>
                        <Grid
                            item
                            lg={12}
                            xs={12}
                            style={{
                                fontWeight: pallet.general.weight_600,
                                fontSize: pallet.general.size12,
                                display: "flex",
                                verticalAlign: "center",
                                marginBottom: "14px",
                                marginTop: '-3px'
                            }}
                        >
                            {/* {props.proposicao.texto_ementa && (
                                <> */}
                            <Grid style={{ paddingTop: "7px" }}>
                                <ProposicaoConsultaInformacoesModalEspecifica
                                    ementa={props.proposicao?.ementa}
                                    proposicao_id={props.proposicao?.id}
                                    proposicao_descricao={props.proposicao?.descricao}
                                    texto_ementa={props.proposicao?.texto_ementa}
                                />
                            </Grid>
                            {/* </>
                            )} */}
                        </Grid>

                        <Grid container>
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    CASA LEGISLATIVA
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.casa_nome}
                                </Grid>
                            </Grid>

                            {props.proposicao?.integrada === false && props.proposicao?.id_casa >= 32
                                ?

                                <Grid
                                    item
                                    lg={3}
                                    style={{
                                        fontSize: pallet.general.size12,
                                        marginTop: "10px",
                                        paddingLeft: "30px",
                                    }}
                                >
                                    <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                        ESTADO
                                    </Grid>
                                    <Grid
                                        style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                    >
                                        {Estados(props.proposicao?.casa_id_estado)}
                                    </Grid>
                                </Grid>

                                :

                                <Grid></Grid>
                            }
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    DATA DE APRESENTAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.data_apresentacao
                                        ? moment(props.proposicao?.data_apresentacao).format("DD/MM/yyyy")


                                        : ""}
                                </Grid>
                            </Grid>
                            {/* <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    TRAMITAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.tramitacao ? (
                                        props.proposicao?.tramitacao
                                    ) : (
                                        <div style={{ color: pallet.general.color4 }}>
                                            Sem dados no momento
                                        </div>
                                    )}
                                </Grid>
                            </Grid> */}
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    SITUAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.situacao_descricao
                                        ? props.proposicao?.situacao_descricao
                                        // .map((tema) => {
                                        //     return tema.descricao;
                                        // })
                                        // .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid>

                            {/* <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    TEMA LEGISLATIVO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.temas_legislativo.length
                                        ? props.proposicao?.temas_legislativo
                                            .map((tema) => {
                                                return tema.descricao;
                                            })
                                            .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid> */}
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "10px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                    }}
                                >
                                    ULTIMA ATUALIZAÇÃO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.data_atualizacao
                                        ? formatDate(
                                            new Date(props.proposicao?.data_atualizacao),
                                            "dd/MM/yyyy"
                                        )
                                        : ""}
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                lg={12}
                                xl={12}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "25px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid
                                    style={{
                                        fontWeight: pallet.general.weight_600,
                                    }}
                                >
                                    DESPACHO
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.despacho_atual
                                        ? props.proposicao?.despacho_atual
                                        : props.proposicao?.status_despacho
                                            ? props.proposicao?.status_despacho
                                            : "-"}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                style={{
                                    fontSize: pallet.general.size12,
                                    marginTop: "25px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <Grid style={{ fontWeight: pallet.general.weight_600 }}>
                                    REGIME
                                </Grid>
                                <Grid
                                    style={{ marginTop: "4px", fontSize: pallet.general.size14 }}
                                >
                                    {props.proposicao?.proposicao_regime
                                        ? props.proposicao?.proposicao_regime
                                        // .map((tema) => {
                                        //     return tema.descricao;
                                        // })
                                        // .join(", ")
                                        : "Não classificado"}
                                </Grid>
                            </Grid>
                            {getConfig()?.habIa
                                ?
                                <>
                                    {props.ia?.success && props.ia?.response.classificacao
                                        ?
                                        <Grid
                                            item
                                            lg={12}
                                            xl={12}
                                            style={{
                                                fontSize: pallet.general.size12,
                                                marginTop: "25px",
                                                paddingLeft: "30px",
                                            }}
                                        >
                                            <Grid
                                                style={{
                                                    fontWeight: pallet.general.weight_600,
                                                }}
                                            >
                                                CLASSIFICAÇÃO IA
                                            </Grid>
                                            <Grid
                                                style={{ marginTop: "4px", fontSize: pallet.general.size14, whiteSpace: "pre-wrap" }}
                                            >
                                                {props.ia?.response.classificacao.classificacao}
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }
                                    {props.ia?.success && props.ia?.response.sumario
                                        ?
                                        <Grid
                                            item
                                            lg={12}
                                            xl={12}
                                            style={{
                                                fontSize: pallet.general.size12,
                                                marginTop: "25px",
                                                paddingLeft: "30px",
                                            }}
                                        >
                                            <Grid
                                                style={{
                                                    fontWeight: pallet.general.weight_600,
                                                }}
                                            >
                                                SUMÁRIO IA
                                            </Grid>
                                            <Grid
                                                style={{ marginTop: "4px", fontSize: pallet.general.size14, whiteSpace: "pre-wrap" }}
                                            >
                                                {props.ia?.response.sumario?.sumario}
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                        </Grid>
                        <Grid>
                            <Grid
                                item
                                lg={12}
                                style={{
                                    fontWeight: pallet.general.weight_600,
                                    fontSize: pallet.general.size12,
                                    marginLeft: "20px",
                                    marginTop: "25px",
                                }}
                            >
                                LINKS EXTERNOS
                            </Grid>
                            <Grid
                                lg={12}
                                style={{
                                    fontSize: pallet.general.size14,
                                    marginLeft: "20px",
                                    marginTop: "2px",
                                    display: "flex",
                                }}
                            >
                                <Grid style={{ paddingTop: "4px" }}>
                                    <LinkIcon />
                                </Grid>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Grid style={{ paddingTop: "7px" }}>
                                    {props?.proposicao?.link_externo || props?.proposicao?.uri ? (
                                        <a
                                            target="_blank"
                                            style={{
                                                textDecoration: "none",
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                            href={props?.proposicao?.link_externo || props?.proposicao?.uri}
                                        >
                                            Proposta
                                        </a>
                                    ) : (
                                        "Proposta não informada"
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                lg={12}
                                style={{
                                    fontSize: pallet.general.size14,
                                    marginLeft: "20px",
                                    marginTop: "2px",
                                    display: "flex",
                                }}
                            >
                                <Grid style={{ paddingTop: "4px" }}>
                                    <LinkIcon />
                                </Grid>{" "}
                                &nbsp;&nbsp;&nbsp;
                                <Grid style={{ paddingTop: "7px" }}>
                                    {props?.proposicao?.uri_teor ? (
                                        <a
                                            target="_blank"
                                            style={{
                                                textDecoration: "none",
                                                fontWeight: pallet.general.weight_600,
                                            }}
                                            href={props?.proposicao?.uri_teor.replace(
                                                "http:",
                                                "https:"
                                            )}
                                        >
                                            Texto Base
                                        </a>
                                    ) : (
                                        "Texto Base não informado"
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
